//
//

export default {
  name: 'FPCA-Signature-Added',
  /** todo: 2024-01-07 John Yee should we insert this prop since we are "watch:"ing it.  See components/FpcaFormImage.vue */
  /* props: [ 'PDFBytes' ], */ 
  mounted () {
    this.signatureAdded ()
  },
  /** todo: 2024-01-07 John Yee do we need to watch PDFBytes?  We don't have it as a prop.
   *      commenting out because this component does not have PDFBytes anywhere else in the code;
   *      not from a prop, not from any other logic
   */
  /*
  watch: {
    PDFBytes () {
      this.signatureAdded ()
    }
  },
  */
  methods: {
    signatureAdded () {
      this.$emit('signatureAddedDone')
    },
  },
}
